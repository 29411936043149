import React from "react";
import LFBox from "../../shared/LFBox";
import LFTypography from "../../shared/LFTypograpy";
import LFStack from "../../shared/LFStack";
import LFButton from "../../shared/LFButton";
import { makeStyles } from "@mui/styles";
import { Divider } from "@mui/material";
import LFGrid from "../../shared/LFGrid";
import { getWeekDayDateAndMonth } from "../../utils/moment";
import classNames from "classnames";
import { equal, length, ternary } from "../../utils/javascript";
import { ReactComponent as EditIcon } from "../../assets/svg/editIcon.svg";
import { locationPath, shiftStatuses } from "../../utils/constant";
import { useMatch } from "react-router-dom";
import LogoAvatar from "../reusable/LogoAvatar";
const useStyles = makeStyles((theme) => ({
  info: {
    textAlign: "center",
    "& h6": {
      color: theme.palette.secondary.dark,
      fontSize: 23,
      lineHeight: "32px",
      [theme.breakpoints.down("xsm")]: {
        fontSize: 16,
        lineHeight: "20px",
      },
    },
    "& p": {
      marginBottom: 8,
      color: theme.palette.primary.main,
      fontSize: 20,
      lineHeight: "26px",
      [theme.breakpoints.down("xsm")]: {
        fontSize: 14,
        lineHeight: "18px",
      },
    },
    "& svg": {
      flex: "0 0 auto",
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      right: 0,
      margin: 0,
      [theme.breakpoints.down("xsm")]: {
        height: 16,
        width: 16,
        marginTop: 0,
      },
    },
  },
  mainBox: {
    borderRadius: 10,
    backgroundColor: theme.palette.gray.main,
    padding: [[24, 32]],
    textAlign: "center",
    justifyContent: "space-between",
    height: "100%",
    boxSizing: "border-box",
    [theme.breakpoints.down("md")]: {
      backgroundColor: "transparent",
      padding: 0,
    },
    [theme.breakpoints.down("xxsm")]: {
      backgroundColor: theme.palette.gray.main,
      padding: [[10, 12]],
    },
    "& img": {
      width: 64,
      height: 64,
      [theme.breakpoints.down("xsm")]: {
        width: 48,
        height: 48,
        marginBottom: 12,
      },
    },
    "& hr": {
      width: "100%",
      height: 0,
      margin: [[20, 0]],
      borderColor: theme.palette.gray.dark,
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
      [theme.breakpoints.down("xxsm")]: {
        "&:nth-child(2)": {
          display: "block",
        },
      },
    },
    "& h6": {
      [theme.breakpoints.down("xsm")]: {
        fontSize: 14,
      },
    },
    "& p": {
      [theme.breakpoints.down("xsm")]: {
        fontSize: 12,
      },
    },
    "& button": {
      maxWidth: 180,
      width: "100%",
      fontSize: 14,
      padding: [[11, 10]],
    },
  },
  grayBox: {
    "&.MuiBox-root": {
      [theme.breakpoints.down("md")]: {
        borderRadius: 10,
        backgroundColor: theme.palette.gray.main,
        padding: [[24, 32]],
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        boxSizing: "border-box",
        [theme.breakpoints.down("xxsm")]: {
          backgroundColor: "transparent",
          padding: 0,
        },
      },
    },
  },
  statusTag: {
    "&.MuiTypography-root": {
      margin: "16px auto 0",
      width: "100%",
      maxWidth: 120,
      display: "block",
      fontSize: 14,
      lineHeight: "20px",
      textTransform: "uppercase",
      fontWeight: 500,
      borderRadius: 50,
      color: theme.palette.white.main,
      [theme.breakpoints.down("xsm")]: {
        margin: "0 auto",
        padding: 0,
      },
    },
  },
  paid: {
    background: theme.palette.dark.success,
  },
  unfilled: {
    background: theme.palette.secondary.dark,
  },
  unpaid: {
    background: theme.palette.dark.danger,
  },
  pending: {
    background: theme.palette.dark.pending,
  },
  confirmed: {
    background: theme.palette.dark.confirmed,
  },
  complete: {
    background: theme.palette.dark.success,
  },
  ifApplicant: {
    "&.MuiGrid-root": {
      [theme.breakpoints.down("md")]: {
        backgroundColor: theme.palette.gray.main,
        padding: [[32, 24]],
      },
      [theme.breakpoints.down("xsm")]: {
        padding: [[20, 12, 0]],
        borderRadius: "10px 10px 0 0",
      },
      "& hr": {
        [theme.breakpoints.down("xsm")]: {
          display: "block",
        },
        "&:nth-child(2)": {
          [theme.breakpoints.down("md")]: {
            display: "block",
          },
        },
      },
    },
  },
  ifApplicantInnerBox: {
    "&.MuiBox-root": {
      [theme.breakpoints.down("md")]: {
        padding: 0,
      },
    },
  },
}));
const ShiftOverview = ({
  roleName,
  businessDetail,
  buttonAttrributes,
  shiftDateObj,
  shiftStatus,
  viewDetails,
  setShowCalendar,
  currentForm,
  className,
}) => {
  const classes = useStyles();
  let shiftDate = getWeekDayDateAndMonth(shiftDateObj?.startDate);
  const match = useMatch(locationPath.viewShift);

  if (shiftDateObj?.endDate) {
    const shiftEndDate = getWeekDayDateAndMonth(shiftDateObj?.endDate);
    if (!equal(shiftDate, shiftEndDate)) {
      shiftDate += ` - ${shiftEndDate}`;
    }
  }
  const displayDate = match
    ? getWeekDayDateAndMonth(shiftDateObj?.startDate?.split("T")[0])
    : shiftDate;
  return (
    <>
      <LFGrid
        container
        spacing={0}
        className={classNames(classes.mainBox, {
          [classes.ifApplicant]: viewDetails,
          [className]: className,
        })}
      >
        <LFGrid
          item
          md={12}
          xxsm={ternary(viewDetails, 12, 6)}
          xs={12}
          pr={{ md: 0, xxsm: ternary(viewDetails, 0, 1.5), xs: 0 }}
        >
          <LFBox
            className={`${classes.info} ${classNames(classes.grayBox, {
              [classes.ifApplicantInnerBox]: viewDetails,
            })}`}
          >
            <LFStack
              direction="row"
              alignItems="center"
              justifyContent="center"
              mb={1}
              position="relative"
              width="100%"
              px={3}
              boxSizing="border-box"
            >
              <LFTypography variant="h6">{displayDate} </LFTypography>
              {viewDetails && equal(shiftStatus, shiftStatuses.unfilled) && (
                <EditIcon
                  className={classes.editIcon}
                  cursor="pointer"
                  onClick={() => setShowCalendar(true)}
                />
              )}
            </LFStack>

            <LFTypography>{roleName}</LFTypography>
            {shiftStatus && (
              <LFTypography
                variant="span"
                className={classNames(classes.statusTag, {
                  [classes[shiftStatus.toLowerCase()]]: true,
                })}
              >
                {equal(shiftStatus, shiftStatuses.unpaid)
                  ? shiftStatuses?.pending?.toUpperCase()
                  : shiftStatus?.toUpperCase()}
              </LFTypography>
            )}
          </LFBox>
        </LFGrid>
        <Divider />
        <LFGrid
          item
          md={12}
          xxsm={ternary(viewDetails, 12, 6)}
          xs={12}
          pl={{ md: 0, xxsm: ternary(viewDetails, 0, 1.5), xs: 0 }}
        >
          <LFBox
            mx={"auto"}
            className={`${classes.grayBox} ${classNames(classes.mainBox, {
              [classes.ifApplicantInnerBox]: viewDetails,
            })}`}
          >
            <LogoAvatar
              sx={{ width: 64, height: 64 }}
              name={businessDetail?.name}
              logoUrl={businessDetail?.logo_url}
            />
            <LFTypography variant="h6" component="h6" mt={2}>
              {businessDetail?.name}
            </LFTypography>
            <LFTypography color="secondary.dark">
              {businessDetail?.address}
            </LFTypography>
          </LFBox>
        </LFGrid>
        {length(buttonAttrributes) && (
          <>
            <Divider />
            <LFGrid item xs={12}>
              <LFStack
                sx={{ display: { md: "flex", xs: "none" } }}
                alignItems="center"
                flexDirection="column"
                spacing={2.5}
              >
                {buttonAttrributes?.map(
                  ({ label, disabled, ...attr }, index) => (
                    <LFButton
                      key={index}
                      {...attr}
                      disabled={ternary(disabled, disabled, !!currentForm)}
                    >
                      {label}
                    </LFButton>
                  ),
                )}
              </LFStack>
            </LFGrid>
          </>
        )}
      </LFGrid>
    </>
  );
};

export default ShiftOverview;
