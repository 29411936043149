import { useDispatch, useSelector } from "react-redux";
import ApiContainer from "../api.container";
import { useEffect, useRef } from "react";
import {
  apiEndpoints,
  apiMethods,
  locationPath,
  statusCodes,
} from "../../utils/constant";
import {
  SET_APP_DATA,
  SET_BUSINESS_DETAILS,
  SET_SELECTED_BUSINESS_ID,
} from "../../redux/constant";
import { capitlizeWord, equal, head } from "../../utils/javascript";
import { useNavigate } from "react-router-dom";

const BusinessDetailContainer = ({ formPath, setIsEditable }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sectionRefs = useRef({});
  const containerRef = useRef(null);

  const initailLoadingStatus = useSelector(
    (state) => state?.app?.loader?.[formPath?.initial],
  );
  const businessType = useSelector((state) => state?.globalAttr?.businessType);
  const businessDetail = useSelector(
    (state) => state?.app?.data?.[formPath?.parent],
  );
  const businessDeatailState = useSelector(
    (state) => state?.app?.data?.[formPath?.state],
  );
  const selected = businessDeatailState?.selectedId;

  const { performRequest } = ApiContainer();

  useEffect(() => {
    const loadBusinessDetails = async () => {
      const response = await performRequest({
        endPoint: apiEndpoints?.allBusiness,
        method: apiMethods?.get,
        tokenKey: "Authorization",
        showFailedMessage: true,
        needLoader: true,
        parent: formPath?.initial,
      });

      dispatch({
        type: SET_APP_DATA,
        payload: { [formPath?.parent]: response?.data?.business },
      });
      if (!selected) {
        dispatch({
          type: SET_APP_DATA,
          payload: {
            [formPath?.state]: {
              ...businessDeatailState,
              selectedId: head(response?.data?.business)?.attributes?.id,
            },
          },
        });
      }
    };
    loadBusinessDetails();
  }, []);

  const selectdBusinessDetail = businessDetail?.find((detail) =>
    equal(detail?.attributes?.id, selected),
  );

  const handlebusinessChange = (event) => {
    const { value } = event.target;
    dispatch({
      type: SET_APP_DATA,
      payload: {
        [formPath?.state]: { ...businessDeatailState, selectedId: value },
      },
    });
    dispatch({
      type: SET_SELECTED_BUSINESS_ID,
      payload: value,
    });
    setIsEditable(false);
  };

  useEffect(() => {
    if (
      sectionRefs &&
      selectdBusinessDetail &&
      sectionRefs?.current[selectdBusinessDetail?.id]
    ) {
      sectionRefs.current[selectdBusinessDetail?.id].scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [sectionRefs, selectdBusinessDetail]);

  const handleScroll = () => {
    containerRef.current.classList.add("scrolling");
    clearTimeout(containerRef.current.timeout);
    containerRef.current.timeout = setTimeout(() => {
      containerRef.current.classList.remove("scrolling");
    }, 400);
  };

  const handleDetailsEditSubmit = async (formData) => {
    const payload = {
      business: {
        name: formData?.name,
        telephone: formData?.telephone,
        location_attributes: {
          ...formData?.location,
          id: selectdBusinessDetail?.attributes?.location?.id,
        },
      },
      default_logo_id: formData?.default_logo_id,
    };

    const response = await performRequest({
      endPoint: `${apiEndpoints?.editBusiness}/${selected}`,
      method: apiMethods?.put,
      tokenKey: "Authorization",
      data: { ...payload },
      showFailedMessage: true,
      parent: formPath?.parent,
      needLoader: true,
    });

    if (equal(response?.statusCode, statusCodes?.OK)) {
      const updatedBusinessDetails = businessDetail?.map((detail) => {
        if (equal(detail?.attributes?.id, selected)) {
          return response?.data?.business;
        }
        return detail;
      });

      dispatch({
        type: SET_APP_DATA,
        payload: { [formPath?.parent]: [...updatedBusinessDetails] },
      });
      dispatch({
        type: SET_BUSINESS_DETAILS,
        payload: updatedBusinessDetails?.map((cur) => cur.attributes),
      });

      return navigate(locationPath?.changesSuccess, {
        state: { redirect: locationPath?.businessDetail, isEdited: true },
      });
    }
  };

  const handleAddBusinessClick = () => {
    navigate(locationPath?.registerBusiness, {
      state: { showRegiserBusiness: true, businessType },
    });
  };

  const getDefaultValues = (data) => {
    const { name, telephone, location } = data || {};
    return {
      name,
      telephone,
      location: {
        address: location?.address,
        latitude: location?.latitude,
        longitude: location?.longitude,
      },
    };
  };

  const selectBusinessAttribute = {
    name: "selectBusiness",
    label: capitlizeWord(selectdBusinessDetail?.attributes?.type),
    type: "select",
    value: selected,
    options: businessDetail?.map((business) => ({
      value: business?.attributes?.id,
      label: business?.attributes?.name,
    })),
    onChange: handlebusinessChange,
  };

  const selectedBusinessName = selectdBusinessDetail?.attributes?.name;

  return {
    initailLoadingStatus,
    selectdBusinessDetail,
    selectBusinessAttribute,
    handleDetailsEditSubmit,
    getDefaultValues,
    handleAddBusinessClick,
    selectedBusinessName,
    businessDetail,
    sectionRefs,
    handleScroll,
    containerRef,
  };
};

export default BusinessDetailContainer;
