import React, { useEffect } from "react";
import TabNavbar from "../TabNavbar";
import { paymentTabBarLinks } from "../../../description/account/payments/payments.description";
import LFGrid from "../../../shared/LFGrid";
import { useMediaQuery } from "@mui/material";
import LFBox from "../../../shared/LFBox";
import LFTypography from "../../../shared/LFTypograpy";
import { makeStyles } from "@mui/styles";
import { ReactComponent as EditIcon } from "../../../assets/svg/editIcon.svg";
import LFButton from "../../../shared/LFButton";
import { useSelector } from "react-redux";
import { equal } from "../../../utils/javascript";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { locationPath } from "../../../utils/constant";
import Loader from "../../reusable/Loader";
import LogoAvatar from "../../reusable/LogoAvatar";

const useStyles = makeStyles((theme) => ({
  tabName: {
    "&.MuiTypography-root": {
      fontSize: 24,
      lineHeight: "31px",
      fontWeight: "500",
      paddingBottom: 16,
      borderBottom: `1px solid ${theme.palette.secondary.dark}`,
    },
  },
  mdTHeading: {
    "&.MuiTypography-root": {
      paddingTop: 16,
      fontSize: 20,
      lineHeight: "26px",
    },
  },
  title: {
    "&.MuiTypography-root": {
      paddingTop: 12,
      fontSize: 18,
      lineHeight: "23px",
    },
  },
  subTitle: {
    "&.MuiTypography-root": {
      fontSize: 18,
      lineHeight: "23px",
    },
  },
  switch: {
    "&.MuiSwitch-root": {
      marginLeft: 21,
    },
  },
  paymentMenu: {
    padding: 14,
    borderRadius: 10,
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "transparent",
      padding: 0,
    },
    "& .MuiButtonBase-root": {
      textTransform: "capitalize",
      textDecoration: "none",
      color: theme.palette.primary.main,
      display: "flex",
      alignItems: "center",
      padding: "17px 0",
      width: "100%",
      textAlign: "left",
      borderRadius: 0,
      borderBottom: `1px solid ${theme.palette.secondary.dark}`,
      "& span": {
        marginRight: "auto",
        fontSize: 20,
      },
    },
  },
}));

const Payments = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const mobileMatches = useMediaQuery("(max-width: 767px)");
  const globalAttr = useSelector((state) => state?.globalAttr);
  const businessDetail = globalAttr?.businessDetail;
  const selected = globalAttr?.selectedBusinessId;

  const selectdBusinessDetail = businessDetail?.find((detail) =>
    equal(detail?.id, selected),
  );

  const isMenuPage = equal(pathname, locationPath?.accountPayment);
  const showBusiness = !equal(pathname, locationPath?.paymentMethod);

  useEffect(() => {
    if (isMenuPage) {
      if (!mobileMatches) {
        navigate(locationPath?.paymentMethod, { replace: true });
      }
    }
  }, [pathname, mobileMatches]);

  const handleLinkClick = (value) => {
    navigate(value);
  };
  return (
    <>
      <LFGrid container spacing={3}>
        <LFGrid item md={8} xs={12}>
          <LFTypography
            variant="h6"
            mb={3.5}
            color="primary"
            sx={{
              display: { md: "none", xxsm: "block", xs: "none" },
              fontWeight: 500,
              fontSize: 24,
            }}
          >
            Payments
          </LFTypography>
          {mobileMatches && isMenuPage && (
            <LFBox>
              <LFTypography
                variant="h3"
                color="primary"
                className={classes.tabName}
              >
                Payments
              </LFTypography>
              <nav className={classes.paymentMenu}>
                {paymentTabBarLinks.map(({ label, value }, index) => (
                  <LFButton
                    key={index}
                    disableRipple
                    onClick={() => handleLinkClick(value)}
                  >
                    <LFTypography component="span" variant="body1">
                      {label}
                    </LFTypography>
                    <EditIcon className={classes.editIcon} />
                  </LFButton>
                ))}
              </nav>
            </LFBox>
          )}
          {selected ? (
            <>
              {!mobileMatches && !isMenuPage && (
                <TabNavbar
                  activeTab={pathname}
                  setActiveTab={(path) => navigate(path)}
                  tabBarLink={paymentTabBarLinks}
                />
              )}
              <Outlet />
            </>
          ) : (
            <Loader />
          )}
        </LFGrid>
        <LFGrid item md={4} xs={12} display={{ md: "block", xs: "none" }}>
          {selectdBusinessDetail && showBusiness && (
            <LFBox
              sx={{
                borderRadius: 3,
                backgroundColor: "gray.main",
                p: "34px 24px",
                textAlign: "center",
                "& img": { width: 64, height: 64 },
                "& .MuiTypography-root": {
                  wordWrap: "break-word",
                },
              }}
            >
              <LFBox mb={2} lineHeight={0}>
                <LogoAvatar
                  sx={{ width: 64, height: 64 }}
                  name={selectdBusinessDetail?.name}
                  logoUrl={selectdBusinessDetail?.logo_url}
                />
              </LFBox>
              <LFTypography
                variant="h6"
                component="h6"
                mb={2.5}
                color="dark.main"
              >
                {selectdBusinessDetail?.name}
              </LFTypography>
              <LFTypography color="secondary.dark" lineHeight="20px">
                {selectdBusinessDetail?.address}
              </LFTypography>
            </LFBox>
          )}
        </LFGrid>
      </LFGrid>
    </>
  );
};

export default Payments;
