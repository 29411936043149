import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import getConversationsAction from "../../redux/action/getConversationsAction";
import ApiContainer from "../api.container";
import { useSearchParams } from "react-router-dom";
import { equal } from "../../utils/javascript";

const ChatContactsContainer = ({
  setSelectedTab,
  formPath,
  businessId,
  setShowChat,
  businessConversation,
}) => {
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  const updateQueryParam = (key, value) => {
    const newParams = new URLSearchParams(searchParams);

    newParams.set(key, value);

    setSearchParams(newParams);
  };

  const conversationId = Number(searchParams.get("coverstion_id"));

  const contactLoader = useSelector(
    (state) => state.app?.loader?.[formPath?.parent],
  );
  const contactData = useSelector(
    (state) => state.app?.data?.[formPath?.parent],
  );
  const maxPage = contactData?.[businessId]?.[formPath?.maxPage];

  const scrollableDivRef = useRef(null);

  const { performRequest } = ApiContainer();

  useEffect(() => {
    const handleScroll = () => {
      const scrollableDiv = scrollableDivRef?.current;
      if (
        scrollableDiv?.scrollTop + scrollableDiv?.clientHeight >=
        scrollableDiv?.scrollHeight
      ) {
        if (maxPage > 1) {
          dispatch(
            getConversationsAction({
              formPath,
              performRequest,
              businessId,
            }),
          );
        }
      }
    };

    const scrollableDiv = scrollableDivRef.current;
    scrollableDiv?.addEventListener("scroll", handleScroll);

    return () => {
      scrollableDiv?.removeEventListener("scroll", handleScroll);
    };
  }, [maxPage]);

  useEffect(() => {
    if (conversationId) {
      businessConversation?.data?.map((conversation) => {
        const { id, locum } = {
          ...conversation?.attributes,
        };
        if (equal(id, conversationId)) {
          const { id: userId } = { ...locum };
          handleChatClick(conversationId, userId);
        }
      });
    }
  }, []);

  const handleChatClick = (id, userId) => {
    setShowChat(true);
    setSelectedTab({ id, userId });
    updateQueryParam("coverstion_id", id);
  };

  return { scrollableDivRef, contactLoader, handleChatClick };
};

export default ChatContactsContainer;
