export const transactionsFormPath = {
  parent: "payment-transactions",
  loader: "payment-transactions-loader",
  startDate: "startDate",
  endDate: "endDate",
};

export const defaultValues = {
  startDate: {
    date: "",
    error: false,
  },
  endDate: {
    date: "",
    error: false,
  },
};
