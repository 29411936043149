import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import LFDropDown from "../../shared/LFDropDown";
import { capitlizeWord, equal, length } from "../../utils/javascript";
import { SET_SELECTED_BUSINESS_ID } from "../../redux/constant";
import { useLocation } from "react-router-dom";
import { useSearchParams } from "react-router-dom";

const BusinessSelector = (props) => {
  const dispatch = useDispatch();
  const globalAttr = useSelector((state) => state?.globalAttr);
  const businessDetail = globalAttr?.businessDetail;
  const selected = globalAttr?.selectedBusinessId;
  const { state } = useLocation();
  const selectdBusinessDetail = businessDetail?.find((detail) =>
    equal(detail?.id, selected),
  );

  useEffect(() => {
    if (state?.businessId) {
      handlebusinessChange({ target: { value: state?.businessId } });
    }
  }, [state]);
  const [searchParams, setSearchParams] = useSearchParams();

  const businessId = Number(searchParams.get("business_id"));

  const updateQueryParam = (key, value) => {
    const newParams = new URLSearchParams(searchParams);

    newParams.set(key, value);

    setSearchParams(newParams);
  };

  useEffect(() => {
    if (businessId) {
      handlebusinessChange({ target: { value: businessId } });
    }
  }, []);

  const handlebusinessChange = (event) => {
    updateQueryParam("business_id", event.target.value);
    dispatch({
      type: SET_SELECTED_BUSINESS_ID,
      payload: event?.target?.value,
    });
  };
  const selectBusinessAttribute = {
    name: "selectBusiness",
    label: capitlizeWord(selectdBusinessDetail?.type),
    type: "select",
    value: selected,
    options: businessDetail?.map((business) => ({
      value: business?.id,
      label: business?.name,
    })),
    onChange: handlebusinessChange,
  };

  return (
    <>
      {length(businessDetail) > 1 && (
        <LFDropDown
          variant="outlined"
          labelProps={{
            "&.MuiFormLabel-root": {
              transform: "scale(1)",
              fontSize: 14,
              marginLeft: 0,
              position: "relative",
            },
          }}
          selectSx={{
            "&.MuiInputBase-root": {
              marginTop: 1,
            },
          }}
          MenuProps={{
            sx: {
              "& .MuiButtonBase-root": {
                color: "primary.main",
              },
            },
          }}
          {...props}
          {...selectBusinessAttribute}
        />
      )}
    </>
  );
};

export default BusinessSelector;
