import { Link } from "react-router-dom";
import { locationPath } from "../../utils/constant";
import supportBussinessImg1 from "../../assets/svg/supportBusiness/supportBussiness1.svg";
import supportBussinessImg2 from "../../assets/svg/supportBusiness/supportBussiness2.svg";
import supportBussinessImg3 from "../../assets/svg/supportBusiness/supportBussiness3.svg";
import supportBussinessImg4 from "../../assets/svg/supportBusiness/supportBussiness4.svg";
import supportBussinessImg5 from "../../assets/svg/supportBusiness/supportBussiness5.svg";
import supportBussinessImg6 from "../../assets/svg/supportBusiness/supportBussiness6.svg";
import supportBussinessImg7 from "../../assets/svg/supportBusiness/supportBussiness7.svg";
import supportBussinessImg8 from "../../assets/svg/supportBusiness/supportBussiness8.svg";
import supportBussinessImg9 from "../../assets/svg/supportBusiness/supportBussiness9.svg";
import supportBussinessImg10 from "../../assets/svg/supportBusiness/supportBussiness10.svg";
import supportBussinessImg11 from "../../assets/svg/supportBusiness/supportBussiness11.svg";
import supportBussinessImg12 from "../../assets/svg/supportBusiness/supportBussiness12.svg";
import supportBussinessImg13 from "../../assets/svg/supportBusiness/supportBussiness13.svg";
import supportBussinessImg14 from "../../assets/svg/supportBusiness/supportBussiness14.svg";
import supportBussinessImg15 from "../../assets/svg/supportBusiness/supportBussiness15.svg";
import supportBussinessImg16 from "../../assets/svg/supportBusiness/supportBussiness16.svg";
import supportBussinessImg17 from "../../assets/svg/supportBusiness/supportBussiness17.svg";
import supportBussinessImg18 from "../../assets/svg/supportBusiness/supportBussiness18.svg";
import supportBussinessImg19 from "../../assets/svg/supportBusiness/supportBussiness19.svg";
import supportBussinessImg20 from "../../assets/svg/supportBusiness/supportBussiness20.svg";
import supportBussinessImg21 from "../../assets/svg/supportBusiness/supportBussiness21.svg";
import supportBussinessImg22 from "../../assets/svg/supportBusiness/supportBussiness22.svg";
import supportBussinessImg23 from "../../assets/svg/supportBusiness/supportBussiness23.svg";
import supportBussinessImg24 from "../../assets/svg/supportBusiness/supportBussiness24.svg";

export const registerAccount = {
  title: "Register an account",
  id: "supportBusinessRegisterAccount",
  contentArray: [
    {
      type: "paragraph",
      content: (
        <>
          To start using LocumFind as a business owner, go to our{" "}
          <Link to={locationPath?.welcomeBusiness} target="_blank">
            register
          </Link>{" "}
          page and select register to create an account.
        </>
      ),
    },
    {
      type: "image",
      content: supportBussinessImg1,
    },
    {
      type: "paragraph",
      content:
        "Enter your First Name, Surname and Email Address, and if you agree to our Terms of Use and Client Agreement, check the checkbox and click “Next”.",
    },
    {
      type: "paragraph",
      content:
        "A 5-digit code will be sent to your email from donotreply@locumfind.com.au. Check your junk box and enter the code emailed to you to verify your email address.",
    },
    {
      type: "image",
      content: supportBussinessImg2,
    },
    {
      type: "paragraph",
      content:
        "Enter your password and confirm, and add your business to your account. Your registration is complete.",
    },
  ],
};
export const addPaymentMethod = {
  title: "Adding payment method",
  id: "supportBusinessAddPaymentMethod",
  contentArray: [
    {
      type: "paragraph",
      content:
        "To facilitate payments, LocumFind uses Stripe, a popular online payment processing platform that provides safe and secure transactions for businesses. Currently you can choose to add a credit card, or pay with google pay.",
    },
  ],
};
export const addingABusiness = {
  title: "Adding a business",
  id: "supportBusinessAddingABusiness",
  contentArray: [
    {
      type: "paragraph",
      content:
        "If the user wishes to manage more than one business from their account, they can add a business in the Businesses section.",
    },
    {
      type: "image",
      content: supportBussinessImg3,
    },
  ],
};
export const howToPostAShifts = {
  title: "How To Post A Shifts",
  id: "supportBusinessHowToPostAShifts",
  contentArray: [
    {
      type: "paragraph",
      content:
        "To post a shift, navigate to the My Calendar page and click the “Add Shift” button in the top right corner.",
    },
    {
      type: "image",
      content: supportBussinessImg4,
    },
    {
      type: "paragraph",
      content:
        "Choose Single or Recurring Shift depending on how many shifts you would like to post. Select the date by either typing in the date box or clicking dates on the calendar.",
    },
    {
      type: "image",
      content: supportBussinessImg5,
    },
    {
      type: "paragraph",
      content:
        "If the shift is within 48 hours of the current date, you will be prompted whether you would like to make the shift urgent. Urgent shifts are flagged for locums, increasing the chances of it getting filled.",
    },
    {
      type: "image",
      content: supportBussinessImg6,
    },
    {
      type: "paragraph",
      content:
        "Select your business if you have more than one, and if you’ve created a template already, select “From Template”. Otherwise select “Blank Shift”. For pharmacy owners choose a role: Pharmacist, Dispensary Technician or Pharmacy Assistant and then click “Next”.",
    },
    {
      type: "image",
      content: supportBussinessImg7,
    },
    {
      type: "paragraph",
      content:
        "Fill in the Shift details form by clicking each field. “Pay for Travel”, “Pay for Accommodation” and “Extra Notes” are optional. You can “Save Template” if you want to save these details for future shifts. Once ready, click “Post” to post this shift.",
    },
    {
      type: "image",
      content: supportBussinessImg8,
    },
    {
      type: "paragraph",
      content:
        "LocumFind puts a hold for the total cost of the shift on the business user’s account to streamline the payment process. No money is taken out of the account until the locum has worked the shift and the worked hours have been finalized.",
    },
    {
      type: "paragraph",
      content: "You can view and manage your posted shifts in “My Calendar”.",
    },
    {
      type: "image",
      content: supportBussinessImg9,
    },
    {
      type: "paragraph",
      content:
        "If the shift is marked as urgent, a red exclamation mark will be shown next to the shift.",
    },
    {
      type: "image",
      content: supportBussinessImg10,
    },
  ],
};
export const reviewingApplicants = {
  title: "Reviewing Applicants",
  id: "supportBusinessReviewingApplicants",
  contentArray: [
    {
      type: "paragraph",
      content:
        "Now your shift is posted, locums will be able to see it in their app. You can either wait for locums to apply, or browse locums yourself.",
    },
    {
      type: "paragraph",
      content:
        "When a locum has applied to a shift, you’ll receive an email notification and your calendar will show you have a new applicant.",
    },
    {
      type: "image",
      content: supportBussinessImg11,
    },
    {
      type: "paragraph",
      content:
        "Clicking the highlighted shift will bring up your shift details, and the applicant(s) will be displayed on the right side.",
    },
    {
      type: "image",
      content: supportBussinessImg12,
    },
    {
      type: "paragraph",
      content:
        "Clicking “View Applicants” will bring up the locum’s profile. You can view their skills, experience, CV and more.",
    },
    {
      type: "image",
      content: supportBussinessImg13,
    },
    {
      type: "paragraph",
      content:
        "Accepting the locum’s application will change your shift status to “Confirmed”, and the shift will no longer be visible to other locums. Any other pending applicants for this shift will be removed, and the shift status in My Calendar and Shift Details will be updated.",
    },
    {
      type: "image",
      content: supportBussinessImg14,
    },
  ],
};
export const editingAShift = {
  title: "Editing a shift",
  id: "supportBusinessEditingAShift",
  contentArray: [
    {
      type: "paragraph",
      content:
        "In the event that shift details need to be edited (eg: shift rate, or start/end time) the business can change this in the Shift Details page.",
    },
    {
      type: "paragraph",
      content: "For an unfilled shift, this will update the listing.",
    },
    {
      type: "paragraph",
      content: (
        <>
          If a locum is confirmed for a shift, and the <u>rate</u> or the{" "}
          <u>time</u> is amended, the shift status will change to Pending
          status.,
        </>
      ),
    },
    {
      type: "image",
      content: supportBussinessImg15,
    },
    {
      type: "paragraph",
      content:
        "Once the locum has accepted these changes, the shift status will be reverted back to confirmed.",
    },
  ],
};
export const calendarFilters = {
  title: "Calendar filters",
  id: "supportBusinessCalendarFilters",
  contentArray: [
    {
      type: "paragraph",
      content:
        "You can customize what’s displayed on your My Calendar page by utilizing the checkboxes and business dropdown box located above the calendar.",
    },

    {
      type: "image",
      content: supportBussinessImg16,
    },
  ],
};
export const browseLocums = {
  title: "Browse locums",
  id: "supportBusinessBrowseLocums",
  contentArray: [
    {
      type: "paragraph",
      content:
        "LocumFind gives businesses the option to browse, message and invite locums to shifts. Use the left panel to refine your search. You can access their profile and message them directly.",
    },

    {
      type: "image",
      content: supportBussinessImg17,
    },
  ],
};
export const messaging = {
  title: "Messaging",
  id: "supportBusinessMessaging",
  contentArray: [
    {
      type: "paragraph",
      content:
        "You can communicate with locums by selecting the Message icon in the header or clicking “Send Message” within their profile. Discuss important information about your shift(s) or send invites to your unfilled shifts.",
    },

    {
      type: "image",
      content: supportBussinessImg18,
    },
  ],
};
export const inviteLocum = {
  title: "Invite locum",
  id: "supportBusinessInviteLocum",
  contentArray: [
    {
      type: "paragraph",
      content:
        "In your shift details, clicking the “Invite Locum” button will copy a link to this shift. You can then navigate to your messages and paste this into the conversation with the desired locum. This will post a link to the shift for the locum, allowing them to apply directly to your shift.",
    },
    {
      type: "image",
      content: supportBussinessImg19,
    },
    {
      type: "image",
      content: supportBussinessImg20,
    },
  ],
};
export const paymentProcess = {
  title: "Payment Process",
  id: "supportBusinessPaymentProcess",
  contentArray: [
    {
      type: "paragraph",
      content:
        "After a locum completes a shift, and the hours are finalised, we will send an invoice and payment summary on the following Monday. This invoice will detail the services provided and the total amount due.",
    },
    {
      type: "paragraph",
      content:
        "We kindly ask that you pay the invoice at your earliest convenience via bank transfer. The reference number and bank details to pay LocumFind can be found under Account -> Payments ",
    },
  ],
};
export const finalisingShift = {
  title: "Finalising shift",
  id: "supportBusinessFinalisingShift",
  contentArray: [
    {
      type: "paragraph",
      content: (
        <>
          Once a locum has worked their shift, the shift status will change from
          confirmed to pending. You will be prompted to review the locum’s
          worked hours.
          <br />
          Only time and break can be adjusted.
        </>
      ),
    },
    {
      type: "image",
      content: supportBussinessImg21,
    },
    {
      type: "paragraph",
      content:
        "The business user has 3 days to make any necessary amendments; otherwise the shift will automatically be finalised.",
    },
    {
      type: "paragraph",
      content:
        "Once the shift has been finalised, the shift status will change to complete.",
    },
    {
      type: "image",
      content: supportBussinessImg22,
    },
  ],
};
export const amendShift = {
  title: "Amend shift",
  id: "supportBusinessAmendShift",
  contentArray: [
    {
      type: "paragraph",
      content:
        "In the event the locum’s worked hours are different from the original shift, adjust the Time and/or break fields then click the “Amend Shift” button.",
    },
    {
      type: "paragraph",
      content:
        "The shift status will change to Pending, and the locum has 3 days to confirm these changes.",
    },
    {
      type: "paragraph",
      content:
        "Once confirmed, the new payment amount will be taken from the business user’s account, and the shift status will change to paid.",
    },
  ],
};
export const locumFindServiceFee = {
  title: "LocumFind service fee",
  id: "supportBusinessLocumFindServiceFee",
  contentArray: [
    {
      type: "paragraph",
      content:
        "At LocumFind, our aim is to establish a secure and dependable platform where locums and businesses can connect seamlessly, enabling businesses to efficiently find locums.",
    },
    {
      type: "paragraph",
      content:
        "For this service, LocumFind charges a flat rate to the business. ",
    },
    {
      type: "paragraph",
      content: <b>LocumFind Service fee = $50 excl GST per shift.</b>,
    },
    {
      type: "paragraph",
      content:
        "As an additonal feature, for emergency situations where a locum is needed within 48hours, LocumFind allows businesses to flag their shift as urgent. Marking a shift as urgent prioritizes the shift for locums, significantly increasing the likelihood of it being filled.",
    },
    {
      type: "paragraph",
      content: <b>LocumFind Service fee (urgent) = $60 excl GST per shift.</b>,
    },
  ],
};
export const breakdownofCosts = {
  title: "Breakdown of costs",
  id: "supportBusinessBreakdownofCosts",
  contentArray: [
    {
      type: "paragraph",
      content:
        "LocumFind acts as an employer to locums, and as such the following taxes and charges apply for each locum shift.",
    },
    {
      type: "bulletList",
      content: {
        title: null,
        list: [
          "Locum’s wage (including Super)",
          "Payroll Tax (5%)",
          "Workcover (3%)",
          "LocumFind Service Fee ($50 or $60 for an Urgent Shift)",
          "GST",
        ],
      },
    },
  ],
};
export const cancellation = {
  title: "Cancellation",
  id: "supportBusinessCancellation",
  contentArray: [
    {
      type: "paragraph",
      content:
        "At LocumFind, our goal is to ensure a consistent and dependable platform for both locums and businesses.",
    },
    {
      type: "paragraph",
      content: (
        <>
          We understand that sometimes Businesses situations can change.
          <br />
          Unfilled shifts can be cancelled at anytime.
        </>
      ),
    },

    {
      type: "paragraph",
      content:
        "If it is more than 48 hours before the start time of a confirmed shift, a business user may cancel this shift if the locum is no longer required.",
    },
    {
      type: "bulletList",
      content: {
        title:
          "If a confirmed shift is cancelled and it is within 48 hours before the start time, the business will be charged a cancellation fee which includes: ",
        list: [
          "LocumFind service fee of $50 excluding GST.",
          "Locum reimbursed 3 hours of the agreed upon rate.",
        ],
      },
    },
  ],
};
export const invoicesAndTransactions = {
  title: "Invoices and Shifts",
  id: "supportBusinessInvoicesAndTransactions",
  contentArray: [
    {
      type: "paragraph",
      content: (
        <>
          You can view your invoices and shifts under the Payments section in
          your account. Invoices give a summary of the total expenses for the
          week.
          <br />
          Shifts give a breakdown of the costs for each locum shift.
        </>
      ),
    },
  ],
};
export const statistics = {
  title: "Statistics",
  id: "supportBusinessStatistics",
  contentArray: [
    {
      type: "paragraph",
      content:
        "Tracks your usage of LocumFind providing a brief summary for weekly, monthly and yearly. Tracks  total expenses and number of shifts filled..",
    },
  ],
};

export const notificationsAndSecurity = {
  title: "Notifications and security ",
  id: "supportBusinessNotificationsAndSecurity",
  contentArray: [
    {
      type: "paragraph",
      content:
        "Adjust your notification preferences in your Settings. You can customize how you want to be notified.",
    },
    {
      type: "image",
      content: supportBussinessImg23,
    },
    {
      type: "paragraph",
      content: "Reset your password in the security section of your settings.",
    },
    {
      type: "image",
      content: supportBussinessImg24,
    },
    {
      type: "paragraph",
      content:
        "You can find our Privacy Policy, Terms of Use, Contact us page and more in the footer of our website. ",
    },
  ],
};
