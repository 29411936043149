import React, { Fragment, forwardRef } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import moment from "moment";
import LFBox from "./LFBox";
import { ReactComponent as UrgentIndicator } from "../assets/svg/urgentIndicatorSymbol.svg";
import { equal, keys, ternary } from "../utils/javascript";
import { shiftStatuses } from "../utils/constant";
import { makeStyles } from "@mui/styles";
import LFTypography from "./LFTypograpy";

const useStyles = makeStyles((theme) => ({
  eventWrapper: {
    display: "inline-block",
    [theme.breakpoints.down("xsm")]: {
      display: "flex",
      justifyContent: "center",
    },
    "& span": {
      width: "8px",
      height: "8px",
      borderRadius: "50%",
      marginRight: 4,
      pointerEvents: "none",
      cursor: "pointer",
      fontSize: 12,
      [theme.breakpoints.down("xsm")]: {
        marginRight: 0,
      },
    },
    "& p": {
      marginLeft: 16,
      display: "flex",
      alignItems: "center",
      fontSize: 12,
      color: "#717171",
      cursor: "default",
      lineHeight: "16px",
      [theme.breakpoints.down("md")]: {
        lineHeight: "14px",
      },
      [theme.breakpoints.down("xsm")]: {
        flexDirection: "column",
        fontSize: 8,
        marginLeft: 3,
        lineHeight: "11px",
        "&:first-child": {
          marginLeft: 0,
        },
      },
    },
  },
}));

const LFCalender = (
  { dateClick, selectedDate, statusData, shiftDataLoading, ...rest },
  ref,
) => {
  const customEvents = keys(statusData)?.map((date) => ({
    date: date,
    rendering: "background",
    color: "transparent",
    display: "block",
    customRender: true,
    statusObj: statusData?.[date],
  }));

  const classes = useStyles();

  const eventContentFunction = (arg) => {
    if (arg?.event?.extendedProps?.customRender) {
      const statusObj = arg?.event?.extendedProps?.statusObj;

      return (
        <>
          <div className={classes.eventWrapper}>
            {keys(statusObj)?.map((status) => {
              return (
                <Fragment key={status}>
                  {statusFieldStyles?.[status] && (
                    <LFTypography key={status}>
                      <LFTypography
                        component="span"
                        style={{
                          ...statusFieldStyles?.[status],
                        }}
                      ></LFTypography>
                      x{statusObj?.[status]}
                    </LFTypography>
                  )}
                </Fragment>
              );
            })}
          </div>
        </>
      );
    }
  };

  const dayCellContentFunction = (arg) => {
    const todaysDate = moment().startOf("day");
    const calenderDate = moment(arg?.date);
    const isTodaysDate = equal(
      todaysDate?.format("YYYY-MM-DD"),
      calenderDate?.format("YYYY-MM-DD"),
    );
    const isPastDate = calenderDate.isBefore(todaysDate);

    const isSelected = equal(calenderDate?.format("YYYY-MM-DD"), selectedDate);

    const stringDate = calenderDate?.format("YYYY-MM-DD")?.toString();

    const urgentForcurDate = statusData?.[stringDate]?.is_urgent;

    let isUrgent = ternary(urgentForcurDate, urgentForcurDate, false);

    return (
      <>
        <LFBox
          sx={{
            backgroundColor: isSelected
              ? "#44269A"
              : isTodaysDate
              ? "#82FFCD"
              : "transparent",
            color: isSelected ? "#fff" : isPastDate ? "gray.dark" : "#000",
            height: 24,
            width: 24,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "100%",
            position: "relative",
            cursor: "pointer",
            paddingTop: "2px",
          }}
        >
          {isUrgent && (
            <UrgentIndicator
              style={{ position: "absolute", top: -10, right: -6 }}
            />
          )}
          {arg?.dayNumberText}
        </LFBox>
      </>
    );
  };

  const daysLetterByNumber = (key) => {
    const daysLetterNumber = {
      0: "Su",
      1: "M",
      2: "Tu",
      3: "W",
      4: "Th",
      5: "F",
      6: "Sa",
    };
    return daysLetterNumber[key];
  };

  return (
    <FullCalendar
      ref={ref}
      plugins={[interactionPlugin, dayGridPlugin]}
      initialView="dayGridMonth"
      headerToolbar={false}
      dateClick={
        shiftDataLoading
          ? null
          : (info) => dateClick({ info, eventClick: false })
      }
      events={customEvents}
      eventContent={(arg) => eventContentFunction(arg)}
      eventClick={
        shiftDataLoading
          ? null
          : (info) => dateClick({ info, eventClick: true })
      }
      dayCellContent={(arg) => dayCellContentFunction(arg)}
      showNonCurrentDates={false}
      fixedWeekCount={false}
      dayHeaderContent={(args) => {
        return daysLetterByNumber(moment(args.date).format("d"));
      }}
      firstDay={1}
      {...rest}
    />
  );
};

export default forwardRef(LFCalender);

const statusFieldStyles = {
  [shiftStatuses.unfilled]: {
    backgroundColor: "#717171",
  },
  [shiftStatuses.pending]: {
    backgroundColor: "#EBBB0C",
  },
  [shiftStatuses.confirmed]: {
    backgroundColor: "#48A7FF",
  },
  [shiftStatuses.complete]: {
    backgroundColor: "#1FD01F",
  },
  [shiftStatuses.unpaid]: {
    backgroundColor: "#FF0000",
  },
  [shiftStatuses.paid]: {
    backgroundColor: "#1FD01F",
  },
};
