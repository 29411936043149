import React, { useEffect, useState } from "react";
import { apiEndpoints, apiMethods, statusCodes } from "../../../utils/constant";
import ApiContainer from "../../../container/api.container";
import { equal } from "../../../utils/javascript";
import LFSwitch from "../../../shared/LFSwitch";
import LFBox from "../../../shared/LFBox";
import { CircularProgress } from "@mui/material";
import { showToast } from "../../../utils/toastService";
import LFStack from "../../../shared/LFStack";
import LFTypography from "../../../shared/LFTypograpy";

const CalendarSyncSetting = () => {
  const { performRequest } = ApiContainer();

  const [isCalSync, setIsCalSync] = useState(false);
  const [isLoading, SetIsLoading] = useState(false);

  const handleCalendarSync = async (e) => {
    const beforeState = isCalSync;
    setIsCalSync(e?.target?.checked);

    SetIsLoading(true);
    const { data, statusCode } = await performRequest({
      endPoint: e?.target?.checked
        ? apiEndpoints?.calendarSync
        : apiEndpoints?.calendarAsync,
      method: apiMethods?.get,
      tokenKey: "Authorization",
      showFailedMessage: true,
      showSuccessMessage: false,
    });

    if (equal(statusCode, statusCodes?.OK)) {
      if (!e?.target?.checked) showToast("Calendar unsync success", "success");
      if (e?.target?.checked) window.location.href = data;
    } else {
      setIsCalSync(beforeState);
    }
    SetIsLoading(false);
  };

  const calendarSyncStatus = async () => {
    SetIsLoading(true);
    const { data, statusCode } = await performRequest({
      endPoint: apiEndpoints?.calendarSyncStatus,
      method: apiMethods?.get,
      tokenKey: "Authorization",
      showFailedMessage: true,
      showSuccessMessage: false,
    });

    if (equal(statusCode, statusCodes?.OK)) {
      setIsCalSync(data?.google_calendar_access_granted);
    }
    SetIsLoading(false);
  };

  useEffect(() => {
    calendarSyncStatus();
  }, []);

  return (
    <LFStack direction="row" gap={3} sx={{ mb: 4 }}>
      <LFTypography
        sx={{ fontSize: 18, lineHeight: "23px" }}
        color="secondary.dark"
      >
        Calendar Sync
      </LFTypography>
      <LFSwitch
        onChange={handleCalendarSync}
        checked={isCalSync}
        disabled={isLoading}
      />
      {isLoading && (
        <LFBox sx={{ display: "flex" }}>
          <CircularProgress size={20} />
        </LFBox>
      )}
    </LFStack>
  );
};

export default CalendarSyncSetting;
