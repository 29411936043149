import { useDispatch, useSelector } from "react-redux";
import ApiContainer from "../api.container";
import { useNavigate, useParams } from "react-router-dom";
import {
  apiEndpoints,
  apiMethods,
  locationPath,
  statusCodes,
} from "../../utils/constant";
import { equal, hardCloneObj, ternary } from "../../utils/javascript";
import { viewShiftFormPath } from "../../description/viewShift/viewShift.description";
import { SET_APP_DATA, SET_FORM_DATA } from "../../redux/constant";
import setShiftValuesAction from "../../redux/action/setShiftValuesAction";

const NotificationBoxContainer = ({ formPath, onClick, notifcationsData }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const unreadNotificationsCount = useSelector(
    (state) => state?.app?.data?.[formPath?.newcounts],
  );

  const { shiftId } = useParams();

  const { performRequest } = ApiContainer();
  const notificationgettingRead = async (notificationid) => {
    const { data, statusCode } = await performRequest({
      endPoint: `${apiEndpoints?.notifications}/${notificationid}`,
      method: apiMethods?.patch,
      tokenKey: "Authorization",
      showFailedMessage: true,
    });
    if (equal(statusCode, statusCodes?.OK)) {
      const updatedNotification = data?.notification?.attributes;
      const newNotifications = hardCloneObj(notifcationsData);
      const index = newNotifications?.findIndex(
        (curNote) => curNote?.id === updatedNotification?.id,
      );
      newNotifications[index] = updatedNotification;
      dispatch({
        type: SET_APP_DATA,
        payload: {
          [formPath?.parent]: [...newNotifications],
          [formPath?.newcounts]: ternary(
            unreadNotificationsCount > 0,
            unreadNotificationsCount - 1,
            0,
          ),
        },
      });
    }
  };

  const onclickNotification = ({
    notifiable_type,
    notifiable_id,
    read,
    n_type,
    notificationid,
    business_id,
  }) => {
    switch (notifiable_type) {
      case "Shift": {
        if (equal(n_type, "urgent_shift")) {
          navigate(locationPath?.setUrgentShift, {
            state: { fromNotification: true, shiftId: notifiable_id },
          });
        } else {
          dispatch({
            type: SET_FORM_DATA,
            payload: {
              [viewShiftFormPath?.parent]: {},
            },
          });

          if (shiftId) {
            dispatch(
              setShiftValuesAction({
                performRequest,
                shiftId,
                formPath: viewShiftFormPath,
              }),
            );
          }
          navigate(
            `${locationPath?.viewShift.replace(":shiftId", notifiable_id)}`,
          );
        }
        break;
      }
      case "Conversation": {
        navigate(locationPath?.messages);
        break;
      }
      case "UserVoucher": {
        navigate(locationPath?.notifications);
        break;
      }
      case "WeeklyInvoice": {
        navigate(locationPath?.paymentInvoices, {
          state: { invoiceId: notifiable_id, businessId: business_id },
        });
      }
    }
    if (!read) notificationgettingRead(notificationid);
    onClick && onClick();
  };

  return {
    onclickNotification,
  };
};

export default NotificationBoxContainer;
