import React from "react";
import LFBox from "../../shared/LFBox";
import LFTypography from "../../shared/LFTypograpy";
import { length, ternary } from "../../utils/javascript";
import LFButton from "../../shared/LFButton";
import LFStack from "../../shared/LFStack";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import { ExclamationIcon, SuccessIcon } from "../../shared/icons";
import { ReactComponent as CoupenCodeTick } from "../../assets/svg/coupenCodeTick.svg";

const useStyles = makeStyles((theme) => ({
  promptBox: () => ({
    minHeight: "calc(100vh - 268px)",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 24,
    "& svg": {
      marginBottom: 24,
    },
  }),
  bgGray: {
    background: theme.palette.gray.main,
    borderRadius: 10,
  },
  tableMainBox: {
    borderRadius: 10,
    backgroundColor: theme.palette.gray.main,
    padding: 14,
    width: 464,
    margin: "0px auto",
    marginBottom: 20,
    border: `0.84px solid #D9D9D9`,
    [theme.breakpoints.down("xts")]: {
      width: "100%",
    },
  },

  tableRow: {
    "&.MuiStack-root": {
      justifyContent: "space-between",
      flex: 1,
      padding: [[8, 0]],
      borderBottom: "1px solid #DDDDDD",

      "&:first-child": {
        paddingTop: "0",
      },
      "&:last-child": {
        border: "0",
        paddingBottom: "0",
      },
    },
    "& .MuiTypography-root": {
      fontSize: 14,
      lineHeight: "1.215",
    },
  },
  voucherCodeText: {
    display: "flex",
    gap: "8px",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.secondary.main,
    "&.MuiTypography-body1": {
      marginBottom: "-9px",
      marginTop: "-12px",
      "& svg": {
        marginBottom: 0,
      },
    },
  },
}));

const PromptTemplet = ({
  iconSrc,
  mainText,
  subText,
  buttonAttributes,
  locumName,
  alertType,
  bgGray = false,
  showTable = false,
  tableData,
  confirmLocum = false,
  voucherApplied = false,
}) => {
  const classes = useStyles();
  return (
    <LFStack
      className={classNames(classes.promptBox, { [classes.bgGray]: bgGray })}
    >
      <LFBox
        textAlign="center"
        {...(!confirmLocum && { maxWidth: 370 })}
        width="100%"
      >
        <LFBox maxWidth={{ xsm: 370 }} margin="0 auto" textAlign="center">
          {confirmLocum && (
            <LFTypography
              color="primary"
              mb={{ xs: 3, xts: 2.5 }}
              fontSize={24}
              fontWeight={{ xs: 500, xts: 400 }}
              lineHeight="1.292"
              textAlign={{ xs: "left", xts: "center" }}
            >
              Confirm Application
            </LFTypography>
          )}
          {iconSrc && (
            <>
              {locumName ? (
                <LFBox
                  mb={3}
                  sx={{
                    borderRadius: "100%",
                    height: { xsm: 120, xs: 64 },
                    width: { xsm: 120, xs: 64 },
                    margin: { xs: "0 auto 12px auto", xts: "0 auto 16px auto" },
                    overflow: "hidden",
                  }}
                >
                  <img src={iconSrc} alt="Profile Picture" />
                </LFBox>
              ) : (
                <>{alertType ? <ExclamationIcon /> : <SuccessIcon />}</>
              )}
            </>
          )}
          {locumName && (
            <LFTypography
              mb={{ xs: 3, xts: 2.5 }}
              fontSize={20}
              color="dark.main"
              lineHeight="20px"
            >
              {locumName}
            </LFTypography>
          )}
          {mainText && (
            <LFTypography color="primary" mb={ternary(subText, "12px", "20px")}>
              {mainText}
            </LFTypography>
          )}
          {subText && (
            <LFTypography color="secondary.dark" mb={{ xs: 3.75, xts: 2 }}>
              {subText}
            </LFTypography>
          )}
        </LFBox>

        {showTable && (
          <>
            <LFBox className={classes.tableMainBox}>
              {tableData?.map((curData) => {
                return (
                  <LFStack
                    key={curData?.name}
                    direction="row"
                    className={classes.tableRow}
                  >
                    <LFTypography className={classes.tableLabel}>
                      {curData?.name}
                    </LFTypography>
                    <LFTypography sx={{ color: "secondary.dark" }}>
                      {curData?.value}
                    </LFTypography>
                  </LFStack>
                );
              })}
            </LFBox>
            {voucherApplied && (
              <LFTypography className={classes.voucherCodeText}>
                Voucher code applied
                <CoupenCodeTick />
              </LFTypography>
            )}
          </>
        )}
        <LFBox
          maxWidth={370}
          margin="0 auto"
          marginTop={{ xs: confirmLocum ? 3.75 : 6, xts: 2.5 }}
        >
          {length(buttonAttributes) ? (
            <>
              {buttonAttributes?.map(
                ({ label, onClick, variant, sx, loadingStatus }, i) => (
                  <LFButton
                    key={i}
                    onClick={onClick}
                    variant={variant}
                    isLoading={loadingStatus}
                    sx={{
                      padding: "11px 66px",
                      width: "100%",
                      minWidth: { xs: "100%", xts: "342px", sm: "unset" },
                      maxWidth: 174,
                      lineHeight: "18px",
                      fontSize: { xs: "18px", xts: "14px" },
                      ...sx,
                    }}
                  >
                    {label}
                  </LFButton>
                ),
              )}
            </>
          ) : null}
        </LFBox>
      </LFBox>
    </LFStack>
  );
};

export default PromptTemplet;
