import React from "react";
import LFStack from "../../../shared/LFStack";
import LFBox from "../../../shared/LFBox";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import { equal, roundToTwoDecimalPlaces } from "../../../utils/javascript";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  InvoiceCard: {
    background: theme.palette.gray.main,
    marginBottom: 16,
    borderRadius: 10,
    padding: [[8, 12, 13]],
    "&>.MuiPaper-root": {
      background: "transparent",
      width: "100%",
      boxShadow: "none",
      "& div.MuiButtonBase-root": {
        padding: 0,
        "& .MuiAccordionSummary-content": {
          margin: 0,
        },
      },
    },
    "&:last-child": {
      margin: 0,
    },
  },
  invoiceNumber: {
    "&.MuiTypography-root": {
      fontSize: 20,
      marginBottom: 6,
      color: theme.palette.primary.main,
      fontWeight: 500,
      [theme.breakpoints.down("xts")]: {
        fontSize: 14,
      },
      "& span": {
        color: theme.palette.gray.dark,
      },
    },
  },
  invoiceDate: {
    "&.MuiTypography-root": {
      marginBottom: 4,
      fontSize: 16,
      fontWeight: 400,
      color: theme.palette.dark.main,
      [theme.breakpoints.down("xts")]: {
        fontSize: 14,
      },
    },
  },
  invoiceTime: {
    "&.MuiTypography-root": {
      fontSize: 16,
      fontFamily: "'DM Sans',sans-serif",
      color: theme.palette.secondary.dark,
      fontWeight: 400,
      [theme.breakpoints.down("xts")]: {
        fontSize: 14,
      },
    },
  },
  invoicePrice: {
    "&.MuiTypography-root": {
      marginBottom: 4,
      fontSize: 24,
      color: theme.palette.primary.main,
      fontWeight: 500,
      [theme.breakpoints.down("xts")]: {
        fontSize: 16,
      },
    },
  },
  invoiceName: {
    "&.MuiTypography-root": {
      marginBottom: 20,
      fontSize: 18,
      color: theme.palette.primary.main,
      fontWeight: 400,
      [theme.breakpoints.down("xts")]: {
        fontSize: 14,
        marginBottom: 10,
      },
    },
  },
  paidTag: {
    "&.MuiTypography-root": {
      borderRadius: 50,
      fontSize: 14,
      textTransform: "uppercase",
      color: theme.palette.white.main,
      background: theme.palette.dark.success,
      width: "100%",
      maxWidth: 110,
      fontWeight: 500,
      textAlign: "center",
      display: "block",
      marginLeft: "auto",
      fontFamily: "'DM Sans',sans-serif",
      [theme.breakpoints.down("xts")]: {
        maxWidth: 84,
        fontSize: 10,
      },
    },
  },
  accordionDetails: {
    "&.MuiAccordionDetails-root": {
      padding: "12px 0 0",
      marginTop: 13,
      borderTop: `1px solid ${theme.palette.gray.dark}`,
      "& h5": {
        fontSize: 16,
        fontWeight: 500,
        color: theme.palette.dark.main,
        wordBreak: "break-word",
        marginBottom: 2,
        maxWidth: 232,
        width: "100%",
      },
      "& th, & td": {
        padding: "8px 0",
        borderColor: "#DDDDDD",
        fontSize: 14,
      },
      "& th": {
        color: theme.palette.dark.main,
      },
      "& td": {
        color: theme.palette.secondary.dark,
      },

      "& button": {
        margin: "20px auto 0",
        padding: "11px 20px",
        maxWidth: 320,
        fontSize: 14,
        fontWeight: 500,
        width: "100%",
      },
    },
  },
  totalDataTable: {
    marginTop: 60,
    "& tbody": {
      "& tr": {
        "& td, & th": {
          borderBottom: "none",
          paddingTop: "0px !important",
        },
        "&:last-child": {
          "& td, & th": {
            paddingTop: "8px !important",
            paddingBottom: "1px !important",
            borderTop: "1px solid #DDDDDD",
          },
          "& th": {
            fontWeight: 700,
          },
        },
      },
    },
  },
  locamTable: {
    marginBottom: 42,
  },
}));

const InvoiceCard = ({ curInvoice }) => {
  const classes = useStyles();
  const {
    from_date,
    business_name,
    to_date,
    total,
    id,
    invoices,
    total_locum_fee,
    locum_cancellation_amount,
    service_fee,
    gst,
    status,
  } = {
    ...curInvoice,
  };
  const { state } = useLocation();

  const tableData = invoices?.map(({ attributes }) => [
    { name: "Locum Name", value: attributes?.locum?.name },

    {
      ...(attributes?.shift?.status === "chargeable_cancelled"
        ? {
            name: "Locum Cancellation Fee",
            value: `$${roundToTwoDecimalPlaces(attributes?.total_locum_fee)}`,
          }
        : {
            name: "Locum Fee",
            value: `$${roundToTwoDecimalPlaces(attributes?.total_locum_fee)}`,
          }),
    },
    {
      name: "Service Fee",
      value: `$${roundToTwoDecimalPlaces(attributes?.service_fee)}`,
    },
  ]);

  const totalData = [
    {
      ...(total_locum_fee && {
        name: "Total Locum Charges",
        value: `$${roundToTwoDecimalPlaces(total_locum_fee)}`,
      }),
    },
    {
      ...(locum_cancellation_amount && {
        name: "Total Locum Cancellation Fees",
        value: `$${roundToTwoDecimalPlaces(locum_cancellation_amount)}`,
      }),
    },
    {
      name: "LocumFind Service Fee",
      value: `$${roundToTwoDecimalPlaces(service_fee)}`,
    },
    {
      name: "GST",
      value: `$${roundToTwoDecimalPlaces(gst)}`,
    },
  ];
  return (
    <LFStack
      direction="row"
      justifyContent="space-between"
      className={classes.InvoiceCard}
    >
      <Accordion defaultExpanded={equal(state?.invoiceId, id)}>
        <AccordionSummary>
          <LFBox width="100%">
            <LFStack
              direction="row"
              width="100%"
              justifyContent="space-between"
            >
              <LFBox>
                <Typography className={classes.invoiceNumber}>
                  Invoice No. {id}
                </Typography>
                <Typography className={classes.invoiceDate}>
                  {moment(from_date).format("dddd DD MMM YYYY")} -{" "}
                  {moment(to_date).format("dddd DD MMM YYYY")}
                </Typography>
                <Typography variant="span" className={classes.invoiceTime}>
                  {invoices?.length} shifts
                </Typography>
              </LFBox>
              <LFBox textAlign="right" flex="1">
                <Typography className={classes.invoicePrice}>
                  ${roundToTwoDecimalPlaces(total)}
                </Typography>
              </LFBox>
            </LFStack>
            <Typography variant="span" className={classes.paidTag}>
              {status}
            </Typography>
          </LFBox>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <Typography variant="h5">{business_name}</Typography>
          {tableData.map((detail, ind) => (
            <Table
              key={ind}
              aria-label="custom pagination table"
              className={classes.locamTable}
            >
              <TableBody>
                {detail.map((row) => (
                  <TableRow key={row.name}>
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell style={{ width: 160 }} align="right">
                      {row.value}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ))}
          <Table
            aria-label="custom pagination table"
            className={classes.totalDataTable}
          >
            <TableBody>
              {totalData.map((row) =>
                row.name ? (
                  <TableRow key={row.name}>
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell style={{ width: 160 }} align="right">
                      {row.value}
                    </TableCell>
                  </TableRow>
                ) : null,
              )}
              <TableRow>
                <TableCell component="th" scope="row">
                  {"Total Amount"}
                </TableCell>
                <TableCell style={{ width: 160 }} align="right">
                  {`$${roundToTwoDecimalPlaces(total)}`}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </AccordionDetails>
      </Accordion>
    </LFStack>
  );
};

export default InvoiceCard;
