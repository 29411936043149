import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const PaymentMethodContainer = () => {
  const accountStatus = useSelector((state) => state?.app?.data?.accountStatus);

  const [data, setData] = useState([
    { label: "Account Name", value: "Locumfind Pty Ltd" },
    { label: "BSB", value: "033-364" },
    { label: "Account Number", value: "082044" },
    { label: "Bank", value: "Westpac" },
  ]);
  useEffect(() => {
    if (accountStatus?.user?.attributes?.reference_number) {
      setData((prevData) => [
        ...prevData,
        {
          label: "Reference Number",
          value: accountStatus?.user?.attributes?.reference_number,
        },
      ]);
    }
  }, [accountStatus?.user?.attributes?.reference_number]);

  return {
    data,
    accountStatus,
  };
};

export default PaymentMethodContainer;
