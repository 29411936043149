import React from "react";
import LFBox from "../../../shared/LFBox";
import LFButton from "../../../shared/LFButton";
import LFDialog from "../../../shared/LFDialog";
import AccountSettingContainer from "../../../container/account/settings/accountSetting.container";
import LFStack from "../../../shared/LFStack";
import CalendarSyncSetting from "./CalendarSyncSetting";

const AccountSetting = () => {
  const { isOpen, dialogActions, handleOnClose, handleDeleteClick } =
    AccountSettingContainer();
  return (
    <LFBox>
      <CalendarSyncSetting />
      <LFButton
        onClick={handleDeleteClick}
        variant={"contained"}
        color="danger"
        sx={{
          fontSize: 14,
          minHeight: 40,
          maxWidth: 341,
          width: { xs: "100%", xts: "60%", md: "fit-content" },
          "&:hover": { backgroundColor: "danger.main", color: "white.main" },
        }}
      >
        DELETE ACCOUNT
      </LFButton>
      <LFDialog
        open={isOpen}
        onClose={handleOnClose}
        content={"Are you sure you want to delete this account?"}
        actions={
          <LFStack spacing={2} direction="row">
            {dialogActions.map(({ label, onClick, variant }, index) => (
              <LFButton key={index} onClick={onClick} variant={variant}>
                {label}
              </LFButton>
            ))}
          </LFStack>
        }
      />
      {/* <CalendarSyncSetting /> */}
    </LFBox>
  );
};

export default AccountSetting;
