// pharmacy
// optometrist

import { isArray } from "../../utils/javascript";

const filterMenuObject = {
  pharmacy: {
    roles: {
      pharmacist: {
        label: "Pharmacist",
        name: "Pharmacist",
        value: 1,
      },
      dispensaryTechnician: {
        label: "Dispensary Technician",
        name: "Dispensary Technician",
      },
      PharmacyAssistant: {
        label: "Pharmacy Assistant",
        name: "Pharmacy Assistant",
      },
    },
    defaultValues: {
      Pharmacist: true,
      "Dispensary Technician": true,
      "Pharmacy Assistant": true,
    },
  },
  optometrist: {
    roles: {
      Optometrist: {
        label: "Optometrist",
        name: "Optometrist",
      },
    },
    defaultValues: {
      Optometrist: true,
    },
  },
  status: {
    status: {
      confirmed: {
        label: "Confirmed",
        name: "confirmed",
      },
      unfilled: {
        label: "Unfilled",
        name: "unfilled",
      },
      pending: {
        label: "Pending",
        name: "pending",
      },
    },
    defaultValues: {
      confirmed: true,
      unfilled: true,
      pending: true,
      complete: true,
      paid: true,
      unpaid: true,
      payment_failed: true,
    },
  },
};
export default filterMenuObject;

export const myCalendarFormPath = {
  parent: "myCalendar",
  initial: "myCalendar-initial",
  state: "myCalendar-state",
  statusData: "monthly-status",
  shiftData: "selected-month-shifts",
  selectedDayShifts: "selected-day-shifts",
  shiftDataLoading: "selected-day-shifts-loading",
};

export const reduceBusinessDetails = (details) => {
  if (!isArray(details)) return [];
  return details?.map(({ attributes }) => ({
    name: attributes?.name,
    type: attributes?.type,
    id: attributes?.id,
    address: attributes?.location?.address,
    logo_url: attributes?.logo_url,
    business_state: attributes?.business_state,
  }));
};
