import React from "react";
import LFStack from "../../../shared/LFStack";
import LFBox from "../../../shared/LFBox";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import { getDateInSeparate } from "../../../utils/moment";
import { formatName, roundToTwoDecimalPlaces } from "../../../utils/javascript";

const useStyles = makeStyles((theme) => ({
  shiftCard: {
    background: theme.palette.gray.main,
    marginBottom: 16,
    borderRadius: 10,
    padding: [[8, 12, 13]],
    "&>.MuiPaper-root": {
      background: "transparent",
      width: "100%",
      boxShadow: "none",
      "& div.MuiButtonBase-root": {
        padding: 0,
        "& .MuiAccordionSummary-content": {
          margin: 0,
        },
      },
    },
    "&:last-child": {
      margin: 0,
    },
  },
  shiftNumber: {
    "&.MuiTypography-root": {
      fontSize: 20,
      marginBottom: 6,
      lineHeight: "26px",
      color: theme.palette.primary.main,
      fontWeight: 500,
      [theme.breakpoints.down("xts")]: {
        fontSize: 14,
      },
      "& span": {
        color: theme.palette.gray.dark,
      },
    },
  },
  shiftDate: {
    "&.MuiTypography-root": {
      marginBottom: 4,
      fontSize: 16,
      lineHeight: "21px",
      fontWeight: 400,
      color: theme.palette.dark.main,
      [theme.breakpoints.down("xts")]: {
        fontSize: 14,
      },
    },
  },
  shiftTime: {
    "&.MuiTypography-root": {
      fontSize: 16,
      lineHeight: "21px",
      fontFamily: "'DM Sans',sans-serif",
      color: theme.palette.secondary.dark,
      fontWeight: 400,
      [theme.breakpoints.down("xts")]: {
        fontSize: 14,
      },
    },
  },
  shiftPrice: {
    "&.MuiTypography-root": {
      marginBottom: 9,
      fontSize: 20,
      lineHeight: "26px",
      color: theme.palette.primary.main,
      fontWeight: 500,
      [theme.breakpoints.down("xts")]: {
        fontSize: 16,
      },
    },
  },

  shiftName: {
    "&.MuiTypography-root": {
      marginBottom: 20,
      fontSize: 18,
      lineHeight: "23px",
      color: theme.palette.primary.main,
      fontWeight: 400,
      [theme.breakpoints.down("xts")]: {
        fontSize: 14,
        marginBottom: 10,
      },
    },
  },
  paidTag: {
    "&.MuiTypography-root": {
      borderRadius: 50,
      fontSize: 14,
      textTransform: "uppercase",
      color: theme.palette.white.main,
      background: theme.palette.dark.success,
      width: "100%",
      maxWidth: 110,
      fontWeight: 500,
      textAlign: "center",
      display: "block",
      marginLeft: "auto",
      fontFamily: "'DM Sans',sans-serif",
      [theme.breakpoints.down("xts")]: {
        maxWidth: 84,
        fontSize: 10,
      },
    },
  },
  accordionDetails: {
    "&.MuiAccordionDetails-root": {
      padding: "12px 0 0",
      marginTop: 13,
      borderTop: `1px solid ${theme.palette.gray.dark}`,
      "& h5": {
        fontSize: 16,
        fontWeight: 500,
        color: theme.palette.dark.main,
        wordBreak: "break-word",
        marginBottom: 2,
        maxWidth: 232,
        width: "100%",
      },
      "& th, & td": {
        padding: "8px 0",
        borderColor: "#DDDDDD",
        fontSize: 14,
      },
      "& th": {
        color: theme.palette.dark.main,
      },
      "& td": {
        color: theme.palette.secondary.dark,
      },
      "& tbody": {
        "& tr": {
          "&:last-child": {
            "& td, & th": {
              borderBottom: "none",
              paddingBottom: 1,
            },
          },
        },
      },
      "& button": {
        margin: "20px auto 0",
        padding: "11px 20px",
        maxWidth: 320,
        fontSize: 14,
        fontWeight: 500,
        width: "100%",
      },
    },
  },
}));

const ShiftCard = ({ attributes }) => {
  const classes = useStyles();
  const { business_name, rate, end_time, start_time, locum, invoice, id } = {
    ...attributes,
  };
  const tableData = [
    {
      name: "Rate",
      value: `$${rate}/hr (Total $${roundToTwoDecimalPlaces(
        invoice?.locum_amount,
      )})`,
    },

    {
      name: "Work Cover",
      value: `$${roundToTwoDecimalPlaces(invoice?.work_cover)}`,
    },
    {
      name: "Payroll Tax",
      value: `$${roundToTwoDecimalPlaces(invoice?.payroll_tax)}`,
    },
    {
      name: "LocumFind Service Fee",
      value: `$${roundToTwoDecimalPlaces(invoice?.service_fee)}`,
    },
    {
      name: "GST",
      value: `$${roundToTwoDecimalPlaces(invoice?.gst)}`,
    },
    {
      name: "Total",
      value: `$${roundToTwoDecimalPlaces(invoice?.amount_paid)}`,
    },
  ];
  return (
    <LFStack
      direction="row"
      justifyContent="space-between"
      className={classes.shiftCard}
    >
      <Accordion>
        <AccordionSummary>
          <LFStack direction="row" width="100%" justifyContent="space-between">
            {console.log("attributes", attributes)}
            <LFBox>
              <Typography className={classes.shiftNumber}>
                Shift No. {id}
              </Typography>
              <Typography className={classes.shiftDate}>
                {moment(start_time).format("dddd DD MMM YYYY")}
              </Typography>
              <Typography variant="span" className={classes.shiftTime}>
                {`${getDateInSeparate(start_time)} - ${getDateInSeparate(
                  end_time,
                )}`}
              </Typography>
            </LFBox>
            {console.log("invoice", invoice)}
            <LFBox textAlign="right" flex="1">
              <Typography className={classes.shiftPrice}>
                ${roundToTwoDecimalPlaces(invoice?.amount_paid)}
              </Typography>
              <Typography className={classes.shiftName}>
                {formatName(locum?.name)}
              </Typography>
            </LFBox>
          </LFStack>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <Typography variant="h5">{business_name}</Typography>
          <Table aria-label="custom pagination table">
            <TableBody>
              {tableData.map((row) => (
                <TableRow key={row.name}>
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell style={{ width: 160 }} align="right">
                    {row.value}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </AccordionDetails>
      </Accordion>
    </LFStack>
  );
};

export default ShiftCard;
