import { locationPath } from "../../../utils/constant";

export const paymentTabBarLinks = [
  {
    label: "Payment Method",
    value: locationPath?.paymentMethod,
  },
  {
    label: "Invoices",
    value: locationPath?.paymentInvoices,
  },
  {
    label: "Statistics",
    value: locationPath?.paymentStatistics,
  },
  {
    label: "Shifts",
    value: locationPath?.paymentShifts,
  },
];

export const paymentFormPath = {
  parent: "payment-method",
};
