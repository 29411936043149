import React, { Fragment } from "react";
import theme from "../../../themes/theme";
import LFTypography from "../../../shared/LFTypograpy";
import PaymentMethodContainer from "../../../container/payments/paymentMethod.container";
import { paymentFormPath } from "../../../description/account/payments/payments.description";
import { Divider, Skeleton, Typography } from "@mui/material";
import LFStack from "../../../shared/LFStack";
import { makeStyles } from "@mui/styles";
import { equal, length } from "../../../utils/javascript";

const useStyles = makeStyles((theme) => ({
  mainCard: {
    background: theme.palette.gray.main,
    marginBottom: 20,
    borderRadius: 10,
    padding: [[12, 12, 20]],
    "&:last-child": {
      margin: 0,
      paddingBottom: 4,
    },
  },
  details: {
    "&.MuiStack-root": {
      flexDirection: "row",
      padding: [[8, 0]],
      justifyContent: "space-between",
      [theme.breakpoints.down("xts")]: {
        fontSize: 14,
      },
      "& p": {
        fontSize: 14,
        lineHeight: "18px",
      },
      "& span": {
        color: theme.palette.secondary.dark,
        fontSize: 14,
        lineHeight: "18px",
      },
    },
  },
  divider: {
    "&.MuiDivider-root": {
      margin: "2px 0 4px 0",
      borderColor: theme.palette.gray.dark,
    },
  },
}));

const PaymentMethod = () => {
  const classes = useStyles();
  const { data, isLoading } = PaymentMethodContainer({
    formPath: paymentFormPath,
  });

  return (
    <>
      <LFTypography
        variant="h6"
        mb={2.7}
        sx={{
          display: { xs: "block" },
          fontWeight: 400,
          color: theme.palette.dark.main,
        }}
      >
        Bank Transfer
      </LFTypography>
      <LFStack justifyContent="space-between" className={classes.mainCard}>
        <LFTypography
          variant="h6"
          sx={{
            display: { xs: "block" },
            fontWeight: 400,
            lineHeight: 1.1,
            mb: "12px",
            color: theme.palette.primary.main,
          }}
        >
          LocumFind
        </LFTypography>
        <Divider className={classes?.divider} />
        {data?.map(({ label, value }, i) =>
          isLoading ? (
            <Fragment key={i}>
              <Skeleton sx={{ minHeight: 38 }} />
            </Fragment>
          ) : (
            <LFStack
              className={classes.details}
              key={i}
              sx={{
                borderBottom: !equal(i, length(data) - 1)
                  ? `1px solid #DDDDDD`
                  : "",
              }}
            >
              <Typography>{label}</Typography>
              <Typography variant="span">{value}</Typography>
            </LFStack>
          ),
        )}
      </LFStack>
    </>
  );
};

export default PaymentMethod;
