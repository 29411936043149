import LFBox from "../../shared/LFBox";
import LFTypography from "../../shared/LFTypograpy";
import userImage from "../../assets/png/user.jpeg";
import { makeStyles } from "@mui/styles";
import LFStack from "../../shared/LFStack";
import classNames from "classnames";
import { equal, formatName, length } from "../../utils/javascript";
import { themeTime } from "../../utils/moment";
import ChatContactsContainer from "../../container/messages/chatContacts.container";
import { messagesFormPath } from "../../description/messages/messages.description";
import BusinessSelector from "../reusable/BusinessSelector";
import Loader from "../reusable/Loader";
import { useRef, useState } from "react";

const useStyles = makeStyles((theme) => ({
  heading: {
    "&.MuiTypography-root": {
      color: theme.palette.primary.main,
      fontSize: 24,
      fontWeight: 500,
      margin: [[0, 0, 20]],
      [theme.breakpoints.down("xxsm")]: {
        margin: [[0, 0, 16]],
      },
    },
  },
  tabBox: ({ isScrollbarVisible }) => ({
    padding: 12,
    background: theme.palette.gray.main,
    borderRadius: 10,
    minHeight: 400,
    height: "100%",
    flex: 1,
    overflow: "auto",
    boxSizing: "border-box",
    [theme.breakpoints.down("xxsm")]: {
      height: "unset",
      background: "transparent",
      padding: [[0, 0, 0, 12]],
      overflow: "unset",
    },
    "&::-webkit-scrollbar": {
      width: 6,
    },
    "&::-webkit-scrollbar-track": {
      background: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      background: isScrollbarVisible ? "#8a8a8a" : "transparent",
      borderRadius: "5px",
      "&:hover": {
        background: "#8a8a8a",
      },
    },
  }),
  chatTab: {
    "&.MuiStack-root": {
      position: "relative",
      cursor: "pointer",
      padding: [[12, 12, 12, 24]],
      borderBottom: `1px solid ${theme.palette.gray.dark}`,
      flexDirection: "row",
      [theme.breakpoints.down("xxsm")]: {
        padding: [[12, 0]],
      },
      "& img": {
        height: 48,
        width: 48,
        borderRadius: 50,
        marginRight: 8,
        [theme.breakpoints.down("md")]: {
          height: 40,
          width: 40,
        },
      },
    },
  },
  name: {
    "&.MuiTypography-root": {
      color: theme.palette.primary.main,
      fontSize: 16,
      fontWeight: 500,
    },
  },
  time: {
    "&.MuiTypography-root": {
      color: theme.palette.secondary.dark,
      fontSize: 12,
    },
  },
  active: {
    "&.MuiStack-root": {
      background: theme.palette.white.main,
      borderRadius: 10,
      border: "none",
      [theme.breakpoints.down("xxsm")]: {
        borderBottom: `1px solid ${theme.palette.gray.dark}`,
        borderRadius: 0,
      },
    },
  },
  new: {
    position: "relative",
    "&::after": {
      position: "absolute",
      content: "''",
      height: 8,
      width: 8,
      borderRadius: 50,
      background: theme.palette.secondary.main,
      left: 8,
      top: 28,
      [theme.breakpoints.down("xxsm")]: {
        left: -16,
      },
    },
  },
  message: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    wordBreak: "break-word",
    lineHeight: "normal",
    minHeight: 42,
    color: theme.palette.secondary.dark,
    [theme.breakpoints.down("md")]: {
      WebkitLineClamp: 1,
    },
  },
}));
const ChatContacts = ({
  businessConversation,
  selectedTab,
  setSelectedTab,
  businessId,
  setShowChat,
}) => {
  const { scrollableDivRef, contactLoader, handleChatClick } =
    ChatContactsContainer({
      formPath: messagesFormPath,
      setSelectedTab,
      businessId,
      setShowChat,
      businessConversation,
    });

  const [isScrollbarVisible, setIsScrollbarVisible] = useState(false);
  const scrollTimeoutRef = useRef(null);

  const handleScroll = () => {
    setIsScrollbarVisible(true);
    clearTimeout(scrollTimeoutRef.current);
    scrollTimeoutRef.current = setTimeout(
      () => setIsScrollbarVisible(false),
      500,
    );
  };

  const classes = useStyles({ isScrollbarVisible });

  return (
    <>
      <LFTypography className={classes.heading}>Messages</LFTypography>
      <BusinessSelector sx={{ marginBottom: 2 }} />
      <LFBox
        className={classes.tabBox}
        ref={scrollableDivRef}
        onScroll={() => handleScroll()}
      >
        {!length(businessConversation?.data) ? (
          <LFStack className={classNames(classes.chatTab, classes.active)}>
            <LFBox flex="1">
              <LFStack direction="row" justifyContent="space-between">
                <LFTypography className={classes.name}>
                  Start messaging...
                </LFTypography>
              </LFStack>
              <LFTypography className={classes.message}>
                start your first conversation...
              </LFTypography>
            </LFBox>
          </LFStack>
        ) : (
          <>
            {businessConversation?.data?.map((conversation) => {
              const { id, read, last_message, locum } = {
                ...conversation?.attributes,
              };
              const { avatar_url, name, id: userId } = { ...locum };
              return (
                <LFStack
                  key={id}
                  className={classNames(classes.chatTab, {
                    [classes.new]: !read,
                    [classes.active]: equal(selectedTab?.id, id),
                  })}
                  onClick={() => handleChatClick(id, userId)}
                >
                  <img src={avatar_url || userImage} alt="profile photo" />
                  <LFBox flex="1">
                    <LFStack
                      direction="row"
                      justifyContent="space-between"
                      mb={0.5}
                    >
                      <LFTypography className={classes.name}>
                        {formatName(name)}
                      </LFTypography>
                      <LFTypography className={classes.time}>
                        {last_message?.created_at &&
                          themeTime(last_message?.created_at)}
                      </LFTypography>
                    </LFStack>
                    <LFTypography className={classes.message}>
                      {last_message?.body}
                    </LFTypography>
                  </LFBox>
                </LFStack>
              );
            })}
            {contactLoader && <Loader height={"20%"} />}
          </>
        )}
      </LFBox>
    </>
  );
};

export default ChatContacts;
