import userImage from "../../assets/png/user.jpeg";
import LFBox from "../../shared/LFBox";
import { ReactComponent as UrgentIndicator } from "../../assets/svg/urgentIndicatorSymbol.svg";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import LFStack from "../../shared/LFStack";
import { useNavigate } from "react-router-dom";
import { locationPath, shiftStatuses } from "../../utils/constant";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { SET_FORM_DATA } from "../../redux/constant";
import { viewShiftFormPath } from "../../description/viewShift/viewShift.description";
import { equal, formatName } from "../../utils/javascript";
import { getDateInSeparate } from "../../utils/moment";

const useStyles = makeStyles((theme) => ({
  calendarCard: {
    "&.MuiStack-root": {
      padding: [[10, 8, 8, 12]],
      borderRadius: 10,
      background: theme.palette.gray.main,
      marginBottom: 15,
      minHeight: 95,
      boxSizing: "border-box",
      [theme.breakpoints.down("md")]: {
        margin: [[0, 15, 0, 0]],
        minWidth: 272,
      },
      [theme.breakpoints.down("xsm")]: {
        margin: [[0, 0, 12, 0]],
        minWidth: "unset",
      },
      "& h6": {
        fontSize: 16,
        fontWeight: 400,
        lineHeight: "20px",
        color: theme.palette.primary.main,
      },
    },
  },
  name: {
    "&.MuiTypography-root": {
      fontSize: 13,
      lineHeight: "12px",
      fontWeight: 400,
      color: theme.palette.dark.main,
    },
  },
  time: {
    "&.MuiTypography-root": {
      fontSize: 13,
      fontWeight: 400,
      color: theme.palette.secondary.dark,
    },
  },
  tag: {
    fontSize: 10,
    fontWeight: 500,
    color: theme.palette.white.main,
    width: 84,
    margin: "0 0 0 auto",
    padding: [[0.5, 8]],
    borderRadius: 50,
    boxSizing: "border-box",
    textAlign: "center",
  },
  paid: {
    background: theme.palette.dark.success,
  },
  unpaid: {
    background: theme.palette.dark.danger,
  },
  unfilled: {
    background: theme.palette.secondary.dark,
  },
  confirmed: {
    background: theme.palette.dark.confirmed,
  },
  complete: {
    background: theme.palette.dark.success,
  },
  pending: {
    background: theme.palette.dark.pending,
  },
  number: {
    height: 48,
    width: 48,
    fontSize: 14,
    fontWeight: 500,
    position: "relative",
    margin: "0 18px 0 auto",
    "& img": {
      borderRadius: 50,
      height: "100%",
      width: "100%",
    },
    "& span": {
      borderRadius: 50,
      color: theme.palette.white.main,
      background: "#F00",
      width: 27,
      height: 27,
      display: "flex",
      alignItems: "center",
      position: "absolute",
      top: 0,
      right: -13,
      justifyContent: "center",
    },
  },
}));

const ShiftInfoCard = ({ shiftDetails }) => {
  const dispatch = useDispatch();
  const {
    attributes,
    last_applicant_user_url,
    last_applicant_user_name,
    applicant_count,
  } = shiftDetails || {};
  const classes = useStyles();
  const navigate = useNavigate();

  const start_time = getDateInSeparate(attributes?.start_time);
  const end_time = getDateInSeparate(attributes?.end_time);

  const goToViewShiftPage = () => {
    dispatch({
      type: SET_FORM_DATA,
      payload: {
        [viewShiftFormPath?.parent]: {},
      },
    });
    navigate(`${locationPath?.viewShift.replace(":shiftId", attributes?.id)}`);
  };

  return (
    <>
      <LFStack
        className={classes.calendarCard}
        sx={{ cursor: "pointer" }}
        onClick={goToViewShiftPage}
      >
        <LFStack direction="row">
          <LFStack>
            <Typography variant="h6">{attributes?.role_name}</Typography>
            <Typography className={classes.time}>
              {`${start_time} - ${end_time}`}
              {attributes?.time_zone_abbreviation &&
                ` (${attributes?.time_zone_abbreviation})`}
            </Typography>
            {last_applicant_user_name && (
              <Typography className={classes.name}>
                {formatName(last_applicant_user_name)}
              </Typography>
            )}
          </LFStack>
          <LFBox className={classes.number}>
            {applicant_count >= 1 && (
              <>
                {equal(attributes?.status, shiftStatuses?.unfilled) && (
                  <Typography variant="span">{applicant_count}</Typography>
                )}
                <img src={last_applicant_user_url || userImage} alt="" />
              </>
            )}
          </LFBox>
        </LFStack>
        <LFStack direction="row" alignItems="center" mt={"auto"}>
          {attributes?.is_urgent && <UrgentIndicator />}

          <LFBox
            className={classNames(classes.tag, {
              [classes[attributes?.status?.toLowerCase()]]: true,
            })}
          >
            {equal(attributes?.status, shiftStatuses.unpaid)
              ? shiftStatuses?.pending?.toUpperCase()
              : attributes?.status?.toUpperCase()}
          </LFBox>
        </LFStack>
      </LFStack>
    </>
  );
};

export default ShiftInfoCard;
