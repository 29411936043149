import { useState } from "react";
import { useSelector } from "react-redux";
import ApiContainer from "../api.container";
import { apiEndpoints, apiMethods, statusCodes } from "../../utils/constant";
import { equal } from "../../utils/javascript";

const InvoicesContainer = ({ formPath }) => {
  const loadingStatus = useSelector(
    (state) => state?.app?.loader?.[formPath?.parent],
  );
  const [invoices, setInvoices] = useState(null);

  const { performRequest } = ApiContainer();

  const apiCall = async (startDate, endDate, businessId) => {
    const { data, statusCode } = await performRequest({
      endPoint: `${apiEndpoints?.weeklyInvoice?.replace(":id", businessId)}`,
      method: apiMethods?.get,
      queryParams: {
        ...(startDate && { from_date: startDate }),
        ...(endDate && { to_date: endDate }),
      },
      tokenKey: "Authorization",
      needLoader: true,
      parent: formPath?.parent,
      showFailedMessage: true,
    });
    if (!equal(statusCode, statusCodes?.OK)) return setInvoices([]);
    setInvoices(data?.invoices);
  };

  return { loadingStatus, invoices, apiCall };
};

export default InvoicesContainer;
